import * as React from "react";
import { MerchCampaignDebugProps } from "components/flexComponents/MerchCampaign/typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

export const MerchCampaignDebugInfo = (props: MerchCampaignDebugProps) => {
  const { errorMessage, data } = props;
  const campaignsCount = data?.campaignRecommendations?.campaigns.length ?? 0;
  const campaigns = data?.campaignRecommendations?.campaigns?.map((campaign, index) => (
    <>
      <span title="Campaign ID">{campaign?.campaignId}</span>
      <span title="Offer/Destination Count">
        <UitkText inline="strong">({campaign.offers?.length})</UitkText>
        {index < campaignsCount - 1 && <span>, </span>}
      </span>
    </>
  ));

  const responseId = data?.campaignRecommendations?.responseId;

  return (
    <UitkSpacing padding={{ block: "two" }}>
      <div data-testid="merch-campaign-debug-info">
        <div data-testid="merch-campaign-debug-info-campaigns">
          <UitkText inline="strong">Campaigns(Offer/Destination count):</UitkText> {campaigns ?? "Not available."}
        </div>
        {errorMessage && (
          <div data-testid="merch-campaign-debug-info-error-message">
            <UitkText inline="strong">Error Message:</UitkText> {errorMessage}
          </div>
        )}
        {responseId && (
          <div data-testid="merch-campaign-debug-info-response-id">
            <UitkText inline="strong">Response Id:</UitkText> {responseId}
          </div>
        )}

        {data && (
          <>
            <div data-testid="merch-campaign-debug-response">
              <UitkText inline="strong">Response:</UitkText>
            </div>
            <div>
              <textarea rows={20} style={{ width: "90%" }}>
                {JSON.stringify(data, null, 2)}
              </textarea>
            </div>
          </>
        )}
      </div>
    </UitkSpacing>
  );
};

import * as React from "react";
import { useLocation } from "react-router-dom";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkLoader } from "@egds/react-core/loader";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkHeading } from "@egds/react-core/text";
import {
  Action,
  FlexTrackingInfo,
  sendDelayedClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { MerchCampaignsFlexModuleResult } from "typings/microserviceModels/merch-campaigns-flex-module";
import { locKeys } from "./l10n";
import { CampaignHeader } from "../../shared/Merchandising/Common/CampaignHeader/CampaignHeader";
import { CampaignPlaceholder } from "../../shared/Merchandising/Common/CampaignPlaceholder/CampaignPlaceholder";
import {
  CarouselModuleViewTypes,
  MerchCampaignCard,
} from "../../shared/Merchandising/Common/MerchCampaignCard/MerchCampaignCard";

import { CampaignProductType, RecommendedType } from "__generated__/typedefs";

import {
  MerchCampaignProps,
  MerchCampaignComponentProps,
  MerchCampaignQueryComponentProps,
  CampaignDetail,
} from "./typings";
import { useBexApi } from "@shared-ui/bex-api-context";
import { useCampaignRecommendationsWithOffersQuery } from "./hooks/useCampaignRecommendationsWithOffersQuery";
import { MerchCampaignDebugInfo } from "components/flexComponents/MerchCampaign/MerchCampaignDebugInfo";
import { getFmId } from "src/stores/ExperienceTemplateStore";

interface DeviceSize {
  lg: number;
  md: number;
  sm: number;
}
interface VisibleItemsMap {
  [CarouselModuleViewTypes.VR]: DeviceSize;
  [CarouselModuleViewTypes.REDESIGN]: DeviceSize;
  [CarouselModuleViewTypes.DEFAULT]: DeviceSize;
}

const VisibleItemsMap: VisibleItemsMap = {
  [CarouselModuleViewTypes.VR]: {
    lg: 2,
    md: 2,
    sm: 2,
  },
  [CarouselModuleViewTypes.REDESIGN]: {
    lg: 4,
    md: 4,
    sm: 2,
  },
  [CarouselModuleViewTypes.DEFAULT]: {
    lg: 3,
    md: 2,
    sm: 1,
  },
};

const MerchCampaign = withStores(
  "flexViewModel",
  "flexModuleModelStore"
)(
  observer((props: MerchCampaignProps) => {
    const { templateComponent, flexModuleModelStore, flexViewModel } = props;
    const {
      metadata: { id },
    } = templateComponent;

    /* istanbul ignore if */
    if (!flexModuleModelStore || !templateComponent) {
      return null;
    }

    const model = flexModuleModelStore.getModel(id) as MerchCampaignsFlexModuleResult | null;

    if (!model) {
      return null;
    }

    return (
      <MerchCampaignQueryComponent model={model} templateComponent={templateComponent} flexViewModel={flexViewModel} />
    );
  })
);

const MerchCampaignQueryComponent: React.FC<MerchCampaignQueryComponentProps> = (props) => {
  const { model, templateComponent, flexViewModel } = props;
  const {
    numberOfCampaigns,
    paginationEnabled,
    filterByProductType,
    paginationMaxCampaigns,
    filterByRecommendationType,
    enableLivePricing,
  } = model;

  const { formatText } = useLocalization();
  const { context } = useBexApi();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userLatitude = queryParams.get("lat") ?? flexViewModel?.userLocation?.latitude;
  const userLongitude = queryParams.get("long") ?? flexViewModel?.userLocation?.longitude;
  const showDebugView = queryParams.get("debug") === "true";

  const variables = {
    context,
    numCampaigns: numberOfCampaigns,
    maxNumberOfCampaigns: paginationEnabled ? paginationMaxCampaigns : null,
    pageNumberOfCampaigns: 1,
    productType: filterByProductType?.map(
      (product: CampaignProductType) => product.toUpperCase() as CampaignProductType
    ),
    recommendationType: filterByRecommendationType?.map(
      (type: RecommendedType) => type.toUpperCase() as RecommendedType
    ),
    userLocation: {
      userSelectedOrigin: queryParams.get("origin") || undefined,
      userLatitude,
      userLongitude,
    },
    referrer: queryParams.get("referrer") || undefined,
    userTripState: queryParams.get("userTripState") || undefined,
    enableLivePricing,
  };

  const { data, loading, error, fetchMore } = useCampaignRecommendationsWithOffersQuery(variables);

  if (loading) {
    return <CampaignPlaceholder numberOfCampaigns={numberOfCampaigns} formatText={formatText} />;
  }

  if ((error || !data) && !showDebugView) {
    return null;
  }

  return (
    <MerchCampaignComponent
      data={data}
      fetchMore={fetchMore}
      model={model}
      templateComponent={templateComponent}
      flexViewModel={flexViewModel}
      showDebugView={showDebugView}
      errorMessage={error?.message}
    />
  );
};

const MerchCampaignComponent: React.FC<MerchCampaignComponentProps> = (props) => {
  const { data, fetchMore, model, templateComponent, flexViewModel, showDebugView, errorMessage } = props;
  const {
    metadata: { id },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const {
    destinationView,
    displayDate,
    displayDescription,
    displayPrice,
    paginationEnabled,
    paginationMaxCampaigns,
    title,
  } = model;
  const campaigns = data?.campaignRecommendations?.campaigns;

  const { formatText } = useLocalization();
  const track = useClickTracker();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [isLoadingNextPage, setIsLoadingNextPage] = React.useState<boolean>(false);
  const showMoreButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  const paginationReferrer = data?.campaignRecommendations?.paginationInfo?.referrer;

  React.useEffect(() => {
    showMoreButtonRef.current?.blur();

    if (campaigns?.length !== 0) {
      const focusCampaignHeader = document.getElementById(`campaign-header-${campaigns?.length}`);
      focusCampaignHeader?.focus();
    }
  }, []);

  React.useEffect(() => {
    setIsLoadingNextPage(false);
  }, [data]);

  if (campaigns?.length === 0) {
    return null;
  }

  const numberOfVisibleItems =
    VisibleItemsMap[model.view as keyof VisibleItemsMap] ?? VisibleItemsMap[CarouselModuleViewTypes.DEFAULT];

  const shouldRenderOffers = (campaign: CampaignDetail) =>
    campaign.offers?.length && campaign.offers?.length >= 4 && campaign.productTypes.length > 0;

  const trackingCallback = (moduleId: string) => {
    const flexTracking: FlexTrackingInfo = {
      moduleName: moduleId,
      rfrr: `${flexViewModel.trackingContext?.pageName}.pagination`,
      action: Action.CLICK,
    };
    sendDelayedClickstreamTrackEvent(flexTracking, track);
  };

  const renderCarousel = (campaign: CampaignDetail, visibleItem: any) => (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <IsomorphicCarousel
        className="carousel-component"
        itemsVisible={visibleItem}
        buttonText={{
          nextButton: formatText("carousel.item.next"),
          prevButton: formatText("carousel.item.prev"),
        }}
        pageBy={1}
        peek
      >
        {campaign.offers?.map((offer, cardIndex) => (
          <div className="uitk-height-item" key={`${offer.name}-${cardIndex}`}>
            <MerchCampaignCard
              offer={offer}
              cardIndex={cardIndex}
              campaignId={campaign.campaignId}
              productTypes={campaign.productTypes}
              recommendedType={campaign.recommendedType}
              destinationView={destinationView}
              displayDate={displayDate}
              displayDescription={displayDescription}
              displayPrice={displayPrice}
              border
              moduleName="MerchCampaign"
            />
          </div>
        )) || []}
      </IsomorphicCarousel>
    </UitkSpacing>
  );

  const loadMoreCampaigns = (): void => {
    showMoreButtonRef.current?.blur();
    const nextPage = pageNumber + 1;

    setPageNumber(nextPage);
    setIsLoadingNextPage(true);

    fetchMore({
      variables: {
        pageNumberOfCampaigns: nextPage,
        referrer: paginationReferrer ?? queryParams.get("referrer"),
      },
    });
  };

  return (
    <>
      {showDebugView && <MerchCampaignDebugInfo errorMessage={errorMessage} data={data} />}
      <UitkSpacing margin={{ blockend: "three" }}>
        <div data-testid="merch-campaign" className="MerchCampaign" id={id} data-fm={fmId}>
          {title && (
            <UitkSpacing margin={{ blockstart: "four" }} padding={{ blockstart: "four" }}>
              <div>
                <UitkHeading tag="h2" size={2} align="center">
                  {title}
                </UitkHeading>
              </div>
            </UitkSpacing>
          )}

          {campaigns?.filter(shouldRenderOffers).map((campaign, campaignIndex) => (
            <>
              <UitkSpacing padding={{ block: "six" }}>
                <div data-testid="merch-campaign-carousel" key={`campaign-${campaign.campaignId}`}>
                  <CampaignHeader campaign={campaign} campaignRefs={campaignIndex} />
                  <Viewport>
                    <ViewSmall>{renderCarousel(campaign, numberOfVisibleItems)}</ViewSmall>
                    {model.view === CarouselModuleViewTypes.VR ? (
                      <ViewMedium>
                        <UitkSpacing padding={{ inline: "twelve" }}>
                          <UitkLayoutGrid columns={4} space="three">
                            {campaign.offers?.slice(0, 4).map((offer, cardIndex) => (
                              <div
                                className="uitk-height-item"
                                key={`${offer.name}-${cardIndex}`}
                                data-testid="merch-grid"
                              >
                                <MerchCampaignCard
                                  offer={offer}
                                  cardIndex={cardIndex}
                                  campaignId={campaign.campaignId}
                                  productTypes={campaign.productTypes}
                                  recommendedType={campaign.recommendedType}
                                  destinationView={destinationView}
                                  displayDate={displayDate}
                                  displayDescription={displayDescription}
                                  displayPrice={displayPrice}
                                  border
                                  moduleName="MerchCampaign"
                                />
                              </div>
                            )) || []}
                          </UitkLayoutGrid>
                        </UitkSpacing>
                      </ViewMedium>
                    ) : (
                      <ViewMedium>{renderCarousel(campaign, numberOfVisibleItems)}</ViewMedium>
                    )}
                  </Viewport>
                </div>
              </UitkSpacing>
            </>
          ))}

          {paginationEnabled &&
            data?.campaignRecommendations?.paginationInfo?.hasNextPage &&
            !(campaigns?.length === paginationMaxCampaigns) && (
              <UitkLayoutFlex justifyContent="center">
                <UitkSpacing padding={{ blockend: "six" }}>
                  <UitkLayoutFlexItem>
                    <div>
                      <UitkSecondaryButton
                        name="showMoreButton"
                        type="button"
                        onClick={loadMoreCampaigns}
                        domRef={showMoreButtonRef}
                        analytics={{
                          id: "merch.campaign",
                          callback: trackingCallback,
                        }}
                      >
                        {formatText(locKeys.showMore)}
                      </UitkSecondaryButton>
                    </div>
                  </UitkLayoutFlexItem>
                </UitkSpacing>
              </UitkLayoutFlex>
            )}
          {isLoadingNextPage && (
            <UitkLayoutFlex justifyContent="center">
              <UitkSpacing margin={{ blockstart: "four" }}>
                <UitkLayoutFlexItem>
                  <div>
                    <UitkLoader />
                  </div>
                </UitkLayoutFlexItem>
              </UitkSpacing>
            </UitkLayoutFlex>
          )}
        </div>
      </UitkSpacing>
    </>
  );
};

export default MerchCampaign;

import * as React from "react";
import { observer } from "mobx-react";

import { UitkCardPlaceholder } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";

// placeholder for the campaigns which will be displayed until the data is fetched
export const CampaignPlaceholder = observer(
  ({
    numberOfCampaigns,
    formatText,
  }: {
    numberOfCampaigns: number;
    formatText: (key: string, ...args: any[]) => string;
  }) => {
    return (
      <UitkSpacing margin={{ blockend: "three" }}>
        <div data-testid="merch-campaign-placeholder" className="CampaignPlaceholder">
          {[...Array(numberOfCampaigns)].map((e, i) => (
            <UitkSpacing padding={{ block: "six" }} key={`merch-campaign-placeholder-${i}`}>
              <div>
                <UitkSpacing margin={{ inlinestart: "three" }}>
                  <UitkCardPlaceholder className="placeholder-header" />
                </UitkSpacing>
                <UitkSpacing margin={{ inlinestart: "three", blockstart: "two" }}>
                  <UitkCardPlaceholder className="placeholder-subheader" />
                </UitkSpacing>
                <UitkSpacing margin={{ blockstart: "three" }}>
                  <IsomorphicCarousel
                    className="carousel-component"
                    itemsVisible={{
                      lg: 3,
                      md: 2,
                      sm: 1,
                    }}
                    buttonText={{
                      nextButton: formatText("carousel.item.next"),
                      prevButton: formatText("carousel.item.prev"),
                    }}
                    pageBy={1}
                    peek
                  >
                    {[...Array(4)].map((val, index) => (
                      <div key={index}>
                        <UitkCardPlaceholder className="placeholder-card" />
                      </div>
                    ))}
                  </IsomorphicCarousel>
                </UitkSpacing>
              </div>
            </UitkSpacing>
          ))}
        </div>
      </UitkSpacing>
    );
  }
);

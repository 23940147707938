import { useQuery } from "@apollo/client";
import {
  CampaignRecommendationsWithOffersQueryDocument,
  CampaignRecommendationsWithOffersQueryQueryVariables,
} from "__generated__/typedefs";

export function useCampaignRecommendationsWithOffersQuery(
  variables: CampaignRecommendationsWithOffersQueryQueryVariables
) {
  const response = useQuery(CampaignRecommendationsWithOffersQueryDocument, {
    variables,
    ssr: false,
  });

  return { ...response, fetchMore: buildFetchMore(response.fetchMore, variables) };
}

export const buildFetchMore = (fetchMore: any, currentVariables: any) => ({ variables: newVariables }: any): void => {
  /* istanbul ignore next */
  fetchMore({
    updateQuery: (prev: any, { fetchMoreResult }: any) => {
      if (!fetchMoreResult) {
        return prev;
      }

      const prevCampaigns = prev.campaignRecommendations?.campaigns;
      const moreCampaigns = fetchMoreResult.campaignRecommendations?.campaigns;

      return {
        ...prev,
        campaignRecommendations: {
          ...prev.campaignRecommendations,
          paginationInfo: {
            ...prev.campaignRecommendations?.paginationInfo,
            ...fetchMoreResult.campaignRecommendations?.paginationInfo,
          },
          campaigns: [...prevCampaigns, ...moreCampaigns],
        },
      };
    },
    variables: {
      ...currentVariables,
      ...newVariables,
    },
  });
};

export default useCampaignRecommendationsWithOffersQuery;
